import request from '../utils/request';
import config from '../assets/config.json';
import { getUser, getToken } from '@/utils/auth';
/**
 * 
 * @param {*} 用户登录 
 * @returns 
 */

// 获取token
export function login(data) {
  return request({
    method: 'post',
    url: config.loginApi + '/Auth/GteTokenByAccountPwd',
    data: data
  });
}

// 获取用户信息
export function userInfo(data) {
  return request({
    method: 'post',
    url: config.loginApi + '/F_UserInfo/GetUserInfoByToken',
    data: data,
    headers: {
      'Content-Type': "application/json-patch+json"
    }
  });
}

// 获取用户信息
export function getPassword(data) {
  return request({
    method: 'post',
    url: config.loginApi + '/F_UserInfo/Show',
    data: data
  });
}

// 修改密码
export function updateWord(data) {
  return request({
    method: 'post',
    url: config.loginApi + '/F_UserInfo/Update',
    data: data,
    headers: {
      'Content-Type': "application/json-patch+json"
    }
  });
}

// 发送验证码 
export function sendCode(data) {
  return request({
    method: 'post',
    url: config.loginApi + '/Auth/APPSendCode',
    data: data
  });
}

// 根据手机号获取token 
export function phoneToken(data) {
  return request({
    method: 'post',
    url: config.loginApi + '/Auth/GetTokenByPhone',
    data: data
  });
}

// 校验验证码
export function VerificationCode(data) {
  var _ref;
  return request({
    method: 'post',
    url: config.loginApi + '/F_UserInfo/VerificationCode',
    data: data,
    headers: {
      // 'Content-Type': `application/json-patch+json`,
      'Authorization': (_ref = 'Bearer ' + sessionStorage.getItem('Apptoken')) !== null && _ref !== void 0 ? _ref : localStorage.getItem('token')
    }
  });
}