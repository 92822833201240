import TabBar from '../layout/TabBar.vue';
import { setTabStatus } from '@/utils/cookies';
import utils from 'liudonghan';
export default {
  name: 'AppLayout',
  data: function data() {
    return {
      tabbars: [{
        title: '大厅',
        to: {
          name: 'liveBroadcast'
        },
        iconShow: require('@/assets/liveBroadcast/iocn_shouye_x@3x.png'),
        iconHidden: require('@/assets/liveBroadcast/iocn_shouye@3x.png')
      }, {
        title: '活动预告',
        to: {
          name: 'activityPreview'
        },
        iconShow: require('@/assets/liveBroadcast/iocn_wod_x@3x.png'),
        iconHidden: require('@/assets/liveBroadcast/iocn_wod@3x.png')
      }, {
        title: '我的',
        to: {
          name: 'myActivityPreview'
        },
        iconShow: require('@/assets/liveBroadcast/iocn_xiaoxi_x@3x.png'),
        iconHidden: require('@/assets/liveBroadcast/iocn_xiaoxi@3x.png')
      }]
    };
  },
  components: {
    TabBar: TabBar
  },
  watch: {
    $route: function $route(value) {
      var path = window.location.href;
      if (path.indexOf('/liveBroadcast/liveBroadcast') > -1) {
        utils.EventBusManagerUtils.post('changeTabStatus', 0);
        // this.$bus.$emit('changeTabStatus', 0);
        this.$refs.tabbar.active = 0;
        setTabStatus(0);
      }
      if (path.indexOf('/liveBroadcast/activityPreview') > -1) {
        utils.EventBusManagerUtils.post('changeTabStatus', 1);
        // this.$bus.$emit('changeTabStatus', 1);
        this.$refs.tabbar.active = 1;
        setTabStatus(1);
      }
      if (path.indexOf('/liveBroadcast/myActivityPreview') > -1) {
        utils.EventBusManagerUtils.post('changeTabStatus', 2);
        // this.$bus.$emit('changeTabStatus', 2);
        this.$refs.tabbar.active = 2;
        setTabStatus(2);
      }
    }
  },
  methods: {
    handleChange: function handleChange(v) {}
  },
  mounted: function mounted() {
    //设置父元素样式
    this.$parent.$el.style = 'overflow: hidden;';
  },
  created: function created() {}
};