var buildEnv = process.env.NODE_ENV == 'development'; // 没有 buildenv 则默认生产环境
// 本地环境配置
module.exports = {
  title: '信富生活',
  baseUrl: 'http://localhost:9018',
  // 项目地址
  // 正式
  baseApi: 'https://api1.xinfushenghuo.cn/',
  // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  Logistics: 'https://api.tuansudi.com/',
  //  李凯物流本地api请求地址,注意：如果你使用了代理，请设置成'/'
  orderApi: 'https://orders.xinfushenghuo.cn/',
  // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  storeApi: 'https://monitor.9buqi.cn/v1/',
  // 正式店铺相关
  liveApi: 'https://zhibo.xinfushenghuo.cn/',
  //H5直播
  SignApi: ' https://hzd.xinfushenghuo.cn/hzdApi/authorize/',
  //汇知道签字
  h5Api: 'https://h5.xinfushenghuo.cn/#/',
  //汇知道签字
  appnameApi: 'https://addresstest.xinfushenghuo.cn/cpd/',
  //全网点评 -- 查所有平台
  Hjicai: 'https://addresstest.xinfushenghuo.cn/addr/',
  // 汇集采
  HjCPay: 'https://address.xinfushenghuo.cn/auction/',
  // 汇集采支付
  hxzApi: 'https://zhenqg.com:14165/',
  // 汇相知
  expertApi: 'https://address.xinfushenghuo.cn/cpd/',
  //达人商店列表
  jinqun: 'http://zhibo.xinfushenghuo.cn:8015/',
  // 测试
  // baseApi: 'https://csapi1.xinfushenghuo.cn/', // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  // Logistics: 'https://api1.tuansudi.com/', //  李凯物流测试（汇速递物流）本地api请求地址,注意：如果你使用了代理，请设置成'/' 物流相关
  // orderApi: 'https://orders1.xinfushenghuo.cn/', // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  // storeApi: 'https://tapi.9buqi.cn/', // 测试店铺相关
  tapiApi: 'https://monitor.9buqi.cn/v1/',
  // liveApi: 'http://zhibo.youdekan.me:8015/', //H5直播测试勿删
  // ScoreTopApi: "http://114.113.144.175:9090/",

  //下面的不动
  TokenApi: 'https://loginf.lawxp.com/',
  // 李璞微信配置本地api请求地址,注意：如果你使用了代理，请设置成'/' 登录及微信配置相关
  userApi: '/',
  // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  payApi: 'https://payapi.lawxp.com/',
  // 绑定银行卡、快捷支付
  APPID: 'xxx',
  APPSECRET: 'xxx',
  $cdn: 'https://www.sunniejs.cn/static',
  IMApi: 'https://im.xinfushenghuo.cn/',
  //IM鉴权接口
  // IMApi: 'https://im.xinfushenghuo.cn/', //IM鉴权接口
  // settleinApi: 'http://csapi2.xinfushenghuo.cn/', // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  settleinApi: 'https://api1.xinfushenghuo.cn/',
  // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  // ActivityAPI: 'http://116.90.87.11:39999/' // 中秋活动
  ActivityAPI: 'https://monitor.9buqi.cn/v1/',
  // 中秋活动
  AmbaAPI: 'https://ambapi.lawxp.com',
  //获取阿米巴员工
  jiaofeiApi: 'https://wyapi.lawxp.com/',
  //惠缴费接口
  payNewApi: 'https://address.xinfushenghuo.cn/auction/',
  //惠缴费提交订单获取支付通道接口
  // ElseInfo:'https://im.xinfushenghuo.cn/"' //获取别人信息
  message: 'https://loginf.lawxp.com/',
  //发送短信
  imBaseApi: 'https://im.xinfushenghuo.cn' // 消息推送
};