import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
var state = {
  productInfo: JSON.parse(sessionStorage.getItem('product-info') || '{}'),
  // 产品详情
  cardInfo: JSON.parse(sessionStorage.getItem('ii-card-info') || '{}'),
  // 电子卡详情
  verify_token: '',
  access_token: ''
};
var mutations = {
  SET_ACCESS_TOKEN: function SET_ACCESS_TOKEN(state, token) {
    state.access_token = token;
    sessionStorage.setItem('baidu_access_token', token);
  },
  SET_VERIFY_TOKEN: function SET_VERIFY_TOKEN(state, token) {
    state.verify_token = token;
    sessionStorage.setItem('baidu_verify_token', token);
  },
  SET_PRODUCT_INFO: function SET_PRODUCT_INFO(state, data) {
    state.productInfo = data;
    sessionStorage.setItem('product-info', JSON.stringify(data));
  },
  SET_CARD_INFO: function SET_CARD_INFO(state, data) {
    state.cardInfo = data;
    sessionStorage.setItem('ii-card-info', JSON.stringify(data));
  }
};
var actions = {};
export default {
  state: state,
  mutations: mutations,
  actions: actions
};