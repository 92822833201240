import "core-js/modules/es.object.to-string.js";
import { userInfo } from "../../writeoff/api/login";
import { setUserInfo, getUserInfo } from "../../writeoff/utils/cookies";
import utils from 'liudonghan';
import { setLoginStatus, getLoginStatus } from '@/utils/cookies';
import { GetUserInfoByToken } from '@/api/home/homeApi.js';
import { IMApi } from '@/config';
import request from '@/utils/request.js';
var state = {
  userinfo: getUserInfo() || {},
  // 当前设备信息
  deviceEnvironment: {
    token: null,
    deviceManager: null
  },
  // 用户信息
  userInfoByToken: {},
  // 用户身份
  userIdentity: []
};
var mutations = {
  SET_USER_INFO: function SET_USER_INFO(state, data) {
    state.userinfo = data;
    setUserInfo(data);
  },
  SET_ENVIRONMENT: function SET_ENVIRONMENT(state, data) {
    state.deviceEnvironment.token = data.token;
    state.deviceEnvironment.deviceManager = data.deviceManager;
  },
  SET_USER_INFO_BY_TOKEN: function SET_USER_INFO_BY_TOKEN(state, data) {
    state.userInfoByToken = data;
  },
  SET_USER_IDENTITY: function SET_USER_IDENTITY(state, data) {
    state.userIdentity = data;
  }
};
var actions = {
  get_user_info: function get_user_info(_ref, data) {
    var commit = _ref.commit,
      dispatch = _ref.dispatch;
    return new Promise(function (resolve, reject) {
      userInfo(data).then(function (res) {
        commit('SET_USER_INFO', res.data);
        resolve(res);
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  initDeviceEnvironment: function initDeviceEnvironment(_ref2) {
    var _this = this;
    var commit = _ref2.commit;
    var deviceManager = utils.DeviceManagerUtils.getDeviceManager();
    var token = '';
    utils.DeviceManagerUtils.getDeviceManagerListener(function (ios) {
      // IOS终端
      window.webkit.messageHandlers.getUserToken.postMessage('token');
    }, function (android) {
      // android终端
      token = window.xinfushenghuo.getUserToken();
      window.xinfushenghuo.showActionBar(false);
      window.xinfushenghuo.showStatusBar(false);
      setLoginStatus(token);
    }, function (WeChat) {
      // 微信浏览器终端
      token = getLoginStatus();
    }, function (Miniprogram) {
      // 小程序终端
      if (_this.$route.query.token) {
        token = _this.$route.query.token;
      } else {
        wx.miniProgram.switchTab({
          url: '/pages/search/index'
        });
        return;
      }
      setLoginStatus(token);
    }, function (browser) {
      token = getLoginStatus();
      setLoginStatus(token);
    });
    if (token) {
      GetUserInfoByToken({
        token: token
      }).then(function (res) {
        if (res.code == 0) {
          commit('SET_USER_INFO_BY_TOKEN', res.data);
          request({
            url: IMApi + 'j_user/getUserInfo',
            method: 'post',
            headers: {
              targetId: "user_" + res.data.userId
            }
          }).then(function (res) {
            if (res.code === 200) {
              if (res.result && res.result.routeInfo && res.result.routeInfo.role_list) {
                var role_list = res.result.routeInfo.role_list;
                commit('SET_USER_IDENTITY', role_list);
              }
            }
          });
        }
      });
    }
    commit('SET_ENVIRONMENT', {
      token: token,
      deviceManager: deviceManager
    });
  },
  iosGetUserInfo: function iosGetUserInfo(_ref3, token) {
    var commit = _ref3.commit;
    GetUserInfoByToken({
      token: token
    }).then(function (res) {
      if (res.code == 0) {
        commit('SET_USER_INFO_BY_TOKEN', res.data);
        request({
          url: IMApi + 'j_user/getUserInfo',
          method: 'post',
          headers: {
            targetId: "user_" + res.data.userId
          }
        }).then(function (res) {
          if (res.code === 200) {
            if (res.result && res.result.routeInfo && res.result.routeInfo.role_list) {
              var role_list = res.result.routeInfo.role_list;
              commit('SET_USER_IDENTITY', role_list);
            }
          }
        });
      }
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};