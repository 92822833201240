var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.src && _vm.times != 0 ? _c("div", {
    ref: "wrap",
    staticClass: "x-audio-wrap",
    class: {
      inline: !_vm.block
    },
    on: {
      click: _vm.play
    }
  }, [_c("div", {
    staticClass: "audioplay"
  }, [_c("van-image", {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      src: require("@/assets/img/iocn_bf@3x.png"),
      width: "20"
    }
  }), _c("van-image", {
    staticClass: "audioplayImg",
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      src: require("@/assets/img/tu_yuyin@3x.png"),
      width: "30"
    }
  })], 1), _c("div", {
    class: _vm.duration && _vm.showDuration ? "x-time" : "x-time1"
  }, [_vm._v(" " + _vm._s(_vm.duration && _vm.showDuration ? _vm.duration : _vm.text) + " ")])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };