import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.to-fixed.js";
export default {
  name: 'm-audio',
  props: {
    src: {
      type: String,
      // default:'http://t1.yy54.com:81//fzl/%E9%A3%9E%E5%90%91%E5%88%AB%E4%BA%BA%E7%9A%84%E5%BA%8A.mp3'
      default: ''
    },
    text: {
      type: String,
      default: ""
    },
    showDuration: {
      type: Boolean,
      default: true
    },
    block: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      audio: null,
      animate: false,
      timer: null,
      duration: null,
      times: 0
    };
  },
  mounted: function mounted() {
    var _this = this;
    var regex = /time(.+?)\./g;
    var result;
    while ((result = regex.exec(this.src)) != null) {
      this.times = result[1].replace(/[^0-9]/ig, '');
      this.duration = this.format(result[1].replace(/[^0-9]/ig, ''));
    }
    this.audio = new Audio();
    this.audio.src = this.src;
    // this.audio.addEventListener('canplaythrough', () => {
    //     this.duration = this.format(this.audio.duration)
    // })
    this.audio.onplay = function () {
      _this.animate = true;
      _this.timer = setInterval(function () {
        _this.animate = false;
        setTimeout(function () {
          _this.animate = true;
        }, 50);
      }, 1250);
    };
    this.audio.onpause = function () {
      _this.animate = false;
      _this.timer && clearInterval(_this.timer);
    };
    this.audio.onended = function () {
      _this.animate = false;
      _this.timer && clearInterval(_this.timer);
    };
    window.audioList.push(this.audio); //所有实例加入全局变量
  },

  methods: {
    play: function play() {
      // console.log('222');
      window.audioList.forEach(function (audio) {
        //开始前先关闭所有可能正在运行的实例
        audio.pause();
      });
      if (this.audio.paused) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    },
    format: function format(s) {
      var t = '';
      if (s > -1) {
        var min = Math.floor(s / 60) % 60;
        var sec = s % 60;
        if (min < 10 && min > 0) {
          t += "0";
          t += min + "′";
        }

        // if (sec < 10) { t += "0"; }
        t += sec.toFixed(0) + '′′';
      }
      t = t.replace('.', '\"');
      return t;
    }
  }
};