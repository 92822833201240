import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import user from './modules/user';
import huilicai from './modules/huilicai';
import giftInfo from './modules/giftInfo';
import partner from './modules/partner';
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    user: user,
    huilicai: huilicai,
    giftInfo: giftInfo,
    partner: partner
  },
  getters: getters
});
export default store;