import "vant/es/toast/style";
import _Toast from "vant/es/toast";
var _this = this;
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.match.js";
import router from '@/router/index';
import store from '@/store/index';
import { getLoginStatus, setLoginStatus, getUserInfo } from '@/writeoff/utils/cookies';
import { setSecret } from '@/utils/cookies';
import { viewUrl } from '@/api/home/homeApi.js';
import { generateShare, GetWXSignature, bindUser } from '@/api/home/homeApi.js';
import md5 from 'js-md5';
import wx from 'weixin-js-sdk';
var writeoffList = ['/home-page', '/login-writeoff', '/findPassword', '/destinations', '/scanCode', '/verification', '/sureVerification', '/verificationRecord', '/verificationDetails', '/personal', '/change-password'];
var whiteList = ['/login-writeoff', '/findPassword', '/helpPay'];
// 埋点页面
var maidianList = ['/home', '/', '/shopcard', '/Solitaire', '/Collage', '/Cashback', '/GoodsDetails', '/ContentDetails', '/ContentDetailsNEW', '/MidAutumn', '/newActivity', '/contrastHomePage', '/couponHomePage', '/NoLoginPage', '/liveBroadcast/liveBroadcast', '/PostComments', '/realNameKnow'];
// 随机生成字符串
var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
var shares = ['/home', '/', '/NoLoginPage', '/LiveFloatWindow', '/groundCard', '/PostComments', '/NewUserFuLi', '/contrastHomePage'];
router.beforeEach(function (to, from, next) {
  // console.log(to, from);
  if (writeoffList.includes(to.path)) {
    var token = getLoginStatus();
    if (token) {
      var userInfo = getUserInfo() || {};
      setLoginStatus(token);
      if (userInfo.userId && userInfo.token == token) {
        if (whiteList.includes(to.path)) {
          router.replace({
            path: '/home-page'
          });
        } else {
          next();
        }
      } else {
        localStorage.clear();
        sessionStorage.clear();
        store.dispatch('user/get_user_info', {
          token: token
        }).then(function (res) {
          router.replace({
            path: '/home-page'
          });
        }).catch(function (err) {
          // router.replace({path: '/login'})
        });
      }
    } else {
      if (whiteList.includes(to.path)) {
        next();
      } else {
        _Toast('登录信息失效，请重新登录！');
        router.replace({
          path: '/login-writeoff'
        });
      }
    }
  } else {
    // 埋点
    var device_id = '';
    if (!localStorage.getItem('device_id')) {
      var devs = 'H5V1';
      for (var index = 0; index < 8; index++) {
        devs += arr[parseInt(Math.random() * 36)];
      }
      device_id = devs;
      localStorage.setItem('device_id', device_id);
    } else {
      device_id = localStorage.getItem('device_id');
    }
    var type = to.path == '/home' || to.path == '/' ? 2 : to.path == '/ContentDetails' || to.path == '/ContentDetailsNEW' ? 5 : to.path == '/GoodsDetails' ? 6 : to.path == '/contrastHomePage' ? 14 : to.path == '/shopcard' ? 8 : to.path == 'couponHomePage' ? 13 : to.path == '/Solitaire' ? 16 : to.path == '/Collage' ? 15 : to.path == '/Cashback' ? 17 : to.path == '/MidAutumn' ? 18 : to.path == '/NoLoginPage' ? 37 : to.path == '/liveBroadcast/liveBroadcast' ? 39 : to.path == '/PostComments' ? 41 : to.path == '/realNameKnow' ? 48 : 7;
    var target_id = 0;
    if (maidianList.includes(to.path)) {
      // console.log('路由', to, from, window.location.href);
      // 文章详情
      if (to.path == '/ContentDetails' || to.path == '/ContentDetailsNEW') {
        target_id = to.query.article_id;
      }
      // 商品详情
      if (to.path == '/GoodsDetails') {
        target_id = to.query.id;
      }
      var pro = {
        type: type,
        target_id: target_id,
        share_url: window.location.href,
        dervice: 'h5',
        device_id: device_id,
        channel: localStorage.getItem('channelCode')
      };
      viewUrl(pro).then(function (res) {
        // console.log(res)
      }).catch(function (err) {
        //   Toast.clear()
        // this.loading = false
      });
    }
    //  分享
    if (shares.includes(to.path)) {
      var params = {
        type: 'h5'
      };
      // APP首页（新版）分享
      if (to.path == '/home' || to.path == '/') {
        params.position = 'home';
      }
      // 好物汇首页分享
      else if (to.path == '/NoLoginPage') {
        params.position = 'homeIndexPage';
      }
      //直播汇首页分享
      else if (to.path == '/LiveFloatWindow') {
        params.position = 'FloatWindowLive';
      }
      //爱心汇首页分享
      else if (to.path == '/groundCard') {
        params.position = 'groundCard';
      }
      //全网点评首页分享
      else if (to.path == '/PostComments') {
        params.position = 'PostComments';
      }
      //汇缴费首页分享
      else if (to.path == '/NewUserFuLi') {
        params.position = 'NewUserFuLi';
      }
      // 比一比首页分享
      else if (to.path == '/contrastHomePage') {
        params.position = 'contrastHomePage';
      }
      generateShare(params).then(function (res) {
        if (res.code == 0) {
          var shareInfo = {
            share_content: res.data.share_content,
            share_thumb: res.data.share_thumb,
            share_title: res.data.share_title,
            share_url: res.data.share_url
          };
          var num = new Date().getTime();
          var str = ',H5,H5,,' + num + ',' + 'JSU$E&H@F';
          // // console.log(str)
          var data = {
            Phone: '',
            Time: num,
            DeviceType: 'H5',
            IP: 'H5',
            sign: md5(',H5,H5,,' + num + ',' + 'JSU$E&H@F'),
            type: 2,
            now_url: window.location.href.split('#')[0]
          };

          // console.log('获取信息--------')
          GetWXSignature(data).then(function (res) {
            if (res.code == 0) {
              //res.data.config.debug = true;
              // res.data.config.openTagList = ['wx-open-launch-app']

              // wx.config(res.data.config)
              wx.config({
                appId: res.data.appid,
                // 必填，公众号的唯一标识
                timestamp: res.data.timestamp,
                // 必填，生成签名的时间戳
                nonceStr: res.data.noncestr,
                // 必填，生成签名的随机串
                signature: res.data.signature,
                // 必填，签名，见附录1
                debug: false,
                jsApiList: ['checkJsApi', 'chooseWXPay', 'updateAppMessageShareData', 'updateTimelineShareData'],
                // 必填，需要使用的JS接口列表
                openTagList: ['wx-open-launch-app'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
              });

              var link = window.location.href.split('#')[0] + '#' + window.location.href.split('#')[1];
              // if (link.indexOf('isShare=true') == -1) link = link + '&isShare=true'
              var shareData = {
                title: shareInfo.share_title,
                // 分享标题
                desc: shareInfo.share_content,
                // 分享描述
                link: shareInfo.share_url,
                // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareInfo.share_thumb,
                // 分享图标
                success: function success() {
                  //alert("分享成功 ");
                  // 设置成功
                },
                cancel: function cancel() {
                  // 用户取消分享后执行的回调函数
                  //alert("取消分享");
                },
                fail: function fail() {
                  // 用户分享失败后执行的回调函数
                  //alert("分享失败");
                },
                complete: function complete() {
                  //alert('调用结束')
                }
              };

              //alert(shareData.link);

              wx.ready(function () {
                //需在用户可能点击分享按钮前就先调用
                wx.updateTimelineShareData(shareData);
                wx.updateAppMessageShareData(shareData);
              });
            } else {
              _this.$message.error(res.msg);
            }
          }).catch(function (err) {});
          // window.xinfushenghuo.shareInfo(JSON.stringify(this.shareInfo)) // android
        } else {
          _this.$message.error(res.msg);
        }
      }).catch(function (err) {});
    }
    if (to.query.share_ouid) {
      var u = navigator.userAgent,
        ua = window.navigator.userAgent.toLowerCase();
      // app = navigator.appVersion
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      var _params = {
        invitation_code: '',
        users_id: to.query.share_ouid,
        // 要绑定对象用户ID / 发起分享人的ID
        device: isAndroid ? 1 : isiOS ? 2 : 0
      };
      bindUser(_params).then(function (res) {}).catch(function (err) {});
    }
    next();
  }
});