import _createForOfIteratorHelper from "/Users/shixuran/job/h5/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.keys.js";
import { shareClick, getWeChatOpenId, viewUrl, generateShare, GetWXSignature, bindUser } from '@/api/home/homeApi.js';
import { data } from 'jquery';
import { setLoginStatus, setUserInfo, getLoginStatus, setOpenid, setUnionid, getOpenid } from '@/utils/cookies';
import md5 from 'js-md5';
import wx from 'weixin-js-sdk';
//获取终端
var u = navigator.userAgent,
  ua = window.navigator.userAgent.toLowerCase();
var appId = 'wx1fa3533cab9a04f8';
var isWx = ua.match(/MicroMessenger/i) == 'micromessenger'; //是否在微信浏览器
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //是否安卓终端
var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //是否ios终端

var getEquipment = function getEquipment() {
  var equipment = 'h5';
  if (!isWx) {
    if (isAndroid) equipment = 'android';
    if (isIos) equipment = 'ios';
  }
  return equipment;
};
var fromShare = function fromShare() {
  if (window.location.href.indexOf('_source=share') > -1) {
    setTimeout(function () {
      var data = {
        url: encodeURIComponent(window.location.origin + "/" + window.location.hash)
      };
      if (getLoginStatus()) {
        data.token = getLoginStatus();
      }
      if (getOpenid()) {
        data.openid = getOpenid();
      }
      shareClick(data).then(function (res) {}).catch(function (err) {
        //   Toast.clear()
        // this.loading = false
      });
    }, 500);
  }
};
function getQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
var wxAuthorize = function wxAuthorize() {
  if (isWx) {
    if (!localStorage.getItem('unionid')) {
      //获取code及openId
      var code = getQueryString('code'); // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      var local = window.location.href; //'https://testh5.xinfushenghuo.cn'
      if (code == null || code === '') {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';

        //snsapi_base
      } else {
        getWeChatOpenId({
          code: code,
          port_type: 'h5'
        }).then(function (res) {
          if (res.code == 0) {
            //将微信信息放入缓存中
            setOpenid(res.data.openid);
            setUnionid(res.data.unionid);
            localStorage.setItem('openid', res.data.openid);
            localStorage.setItem('unionid', res.data.unionid);
            //alert(res.data.user_status);
            if (!res.data.user_status) {
              //window.location.href = `${window.location.origin}${window.location.pathname}#/LoginOneClick`
            } else {
              localStorage.setItem('user_info', JSON.stringify(res.data));
              localStorage.setItem('cellphones', res.data.cellphones);
              localStorage.setItem('userid', res.data.id);
              localStorage.setItem('userName', res.data.nicknames.replace(/\s/gi, ''));
              localStorage.setItem('token', res.data.token);
            }

            // 对路由重定向
            window.location.href = "".concat(window.location.origin).concat(window.location.pathname, "/").concat(window.location.hash);
          } else {
            //this.$toast(res.msg);
          }
        });
      }
    } else {}
  }
};
// 随机生成字符串
var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
/** APP锚点触发事件 */
var MdUrl = function MdUrl(type, target_id, share_url, dervice) {
  var device_id = 'H5V1';
  for (var index = 0; index < 8; index++) {
    device_id += arr[parseInt(Math.random() * 36)];
  }
  var pro = {
    type: type,
    target_id: target_id,
    share_url: share_url,
    dervice: dervice,
    device_id: device_id
  };
  viewUrl(pro).then(function (res) {}).catch(function (err) {
    //   Toast.clear()
    // this.loading = false
  });
};

// 分享链接
export function getgenerateShare(param) {
  var _this = this;
  var shareInfo = {};
  var pro = param;
  param.type = 'h5';
  shareInfo = generateShare(pro).then(function (res) {
    if (res.code == 0) {
      return {
        share_content: res.data.share_content,
        share_thumb: res.data.share_thumb,
        share_title: res.data.share_title,
        share_url: res.data.share_url
      };
      // window.xinfushenghuo.shareInfo(JSON.stringify(this.shareInfo)) // android
    } else {
      _this.$message.error(res.msg);
    }
  }).catch(function (err) {});
  return shareInfo;
}
export function getWechatShare(href, shareInfo) {
  var _this2 = this;
  var num = new Date().getTime();
  // // console.log(str)
  var data = {
    Phone: '',
    Time: num,
    DeviceType: 'H5',
    IP: 'H5',
    sign: md5(',H5,H5,,' + num + ',' + 'JSU$E&H@F'),
    type: 2,
    now_url: href
  };
  GetWXSignature(data).then(function (res) {
    if (res.code == 0) {
      //res.data.config.debug = true;
      // res.data.config.openTagList = ['wx-open-launch-app']

      // wx.config(res.data.config)
      wx.config({
        appId: res.data.appid,
        // 必填，公众号的唯一标识
        timestamp: res.data.timestamp,
        // 必填，生成签名的时间戳
        nonceStr: res.data.noncestr,
        // 必填，生成签名的随机串
        signature: res.data.signature,
        // 必填，签名，见附录1
        debug: false,
        jsApiList: ['checkJsApi', 'chooseWXPay', 'updateAppMessageShareData', 'updateTimelineShareData', 'openLocation'],
        // 必填，需要使用的JS接口列表
        openTagList: ['wx-open-launch-app', 'wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      });

      if (shareInfo) {
        var shareData = {
          title: shareInfo.share_title,
          // 分享标题
          desc: shareInfo.share_content,
          // 分享描述
          link: shareInfo.share_url,
          // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareInfo.share_thumb,
          // 分享图标
          success: function success() {
            //alert("分享成功 ");
            // 设置成功
          },
          cancel: function cancel() {
            // 用户取消分享后执行的回调函数
            //alert("取消分享");
          },
          fail: function fail() {
            // 用户分享失败后执行的回调函数
            //alert("分享失败");
          },
          complete: function complete() {
            //alert('调用结束')
          }
        };
        wx.ready(function () {
          //需在用户可能点击分享按钮前就先调用
          wx.updateTimelineShareData(shareData);
          wx.updateAppMessageShareData(shareData);
        });
        wx.error(function (res) {});
      }
    } else {
      _this2.$message.error(res.msg);
    }
  }).catch(function (err) {});
}
// 绑定用户上下级关系
var BindUser = function BindUser() {
  var u = navigator.userAgent,
    ua = window.navigator.userAgent.toLowerCase();
  // app = navigator.appVersion
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  var params = {
    invitation_code: '',
    users_id: getQueryString('fromUserId'),
    // 要绑定对象用户ID / 发起分享人的ID
    device: isAndroid ? 1 : isiOS ? 2 : 0
  };
  bindUser(params).then(function (res) {}).catch(function (err) {});
};

/**
 * todo 电签签证器
 * @param {签名参数} params 
 * @returns 
 */
var getSign = function getSign(params) {
  console.log('签名参数：', params);
  var signStr = '';
  var sortKeys = Object.keys(params).sort();
  var _iterator = _createForOfIteratorHelper(sortKeys),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var key = _step.value;
      signStr += "".concat(key, "=").concat(params[key], "&");
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  signStr += "93302a2fc3c8622307b4a4a562db7cf9";
  var newSign = md5(signStr);
  console.log('签名' + newSign);
  console.log('签名数据：', newSign);
  return newSign;
};
/**
 * todo 安卓信息操作
 * # 配置跳转app参数type
 * 2：文章内容详情页
 * 3：首页
 * 4：商品详情
 * 5：物流（汇速递）
 * 6：我的优惠券
 * 7: 亲子商品详情
 * 8：中秋活动
 * 9：直播间
 * 10：个人主页
 * 11:群聊
 * 12:好物汇首页
 * 13：我的页面
 * 14:领取礼品卡后，查看
 * 15:健康讲堂列表
 * 16：健康讲堂详情
 * 17:直播创建活动支付收银台
 * 18：名片
 * 19：直播创建活动支付0元跳转支付成功页面
 */
var androidInfo = {
  // todo 安卓获取用户信息
  getUserModel: function getUserModel() {
    // alert('信息'+window.xinfushenghuo.getUserModel())
    return JSON.parse(window.xinfushenghuo.getUserModel());
  },
  // todo 安卓获取token
  getUserToken: function getUserToken() {
    return window.xinfushenghuo.getUserToken();
  },
  // todo 安卓隐藏状态来、标题栏
  showActionBar: function showActionBar(status) {
    return status;
  },
  // todo 安卓隐标题栏
  showStatusBar: function showStatusBar(status) {
    return status;
  }
};
/**
 * todo ios信息操作
*/
var iosInfo = {
  // todo ios获取token
  getUserToken: function getUserToken() {
    return window.webkit.messageHandlers.getUserToken.postMessage('token');
  },
  // todo ios获取用户信息
  getUserModel: function getUserModel() {
    return window.webkit.messageHandlers.getUserModel.postMessage('getUserModel');
  }
};
export { isWx, isAndroid, isIos, getEquipment, fromShare, wxAuthorize, MdUrl, BindUser, getSign, androidInfo, iosInfo };