var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("van-tabbar", {
    attrs: {
      fixed: "",
      route: "",
      "active-color": "#ffb200",
      "inactive-color": "#959595"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, _vm._l(_vm.data, function (item, index) {
    return _c("van-tabbar-item", {
      key: index,
      attrs: {
        to: item.to,
        icon: _vm.active == index ? item.iconShow : item.iconHidden
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };