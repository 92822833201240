var state = {
  giftInfo: []
};
var mutations = {
  SET_GIFT_INFO: function SET_GIFT_INFO(state, data) {
    state.giftInfo = data;
  }
};
var actions = {
  // 设置name
  setGiftInfo: function setGiftInfo(_ref, name) {
    var commit = _ref.commit;
    commit('SET_GIFT_INFO', name);
  }
};
export default {
  state: state,
  mutations: mutations,
  actions: actions
};