import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import Cookies from 'js-cookie';
var loginStatus = 'xfsh_token';
var gainSecret = 'xfsh_gainSecret';
var userinfo = 'xfsh_userinfo';
var findPwdDown = 'zhm-mdjs';
var tabbarStatus = 'hzq-tabbar';
var loginStatusbank = 'xfshyh_token';
var useridbank = 'xfshyh_userid';
var usertarget = 'targetId';
var activity = 'liveActivity';
// 设置登陆状态和失效，默认7天
export function setActivity(value, day) {
  // sessionStorage.setItem(loginStatus,value)
  Cookies.set(activity, JSON.stringify(value), {
    expires: day ? day : 1
  });
}

// 获取登录状态
export function getActivity() {
  // return sessionStorage.getItem(loginStatus)
  return undefined == Cookies.get(activity) ? '' : Cookies.get(activity);
}

// 设置登陆状态和失效，默认7天
export function setLoginStatus(value, day) {
  // sessionStorage.setItem(loginStatus,value)
  Cookies.set(loginStatus, value, {
    expires: day ? day : 10
  });
}

// 获取登录状态
export function getLoginStatus() {
  // return sessionStorage.getItem(loginStatus)
  return undefined == Cookies.get(loginStatus) ? '' : Cookies.get(loginStatus);
}

// 清空登录状态
export function clearLoginStatus() {
  // sessionStorage.removeItem(loginStatus)
  Cookies.remove(loginStatus);
}

// 保存用户信息
export function setUserInfo(value, day) {
  // sessionStorage.setItem(userinfo, JSON.stringify(value))
  Cookies.set(userinfo, JSON.stringify(value), {
    expires: day ? day : 10
  });
}

// 获取用户信息
export function getUserInfo() {
  // return JSON.parse(sessionStorage.getItem(userinfo))
  return Cookies.get(userinfo) ? JSON.parse(Cookies.get(userinfo)) : '';
}
// 清空用户信息
export function clearuserinfo() {
  // sessionStorage.removeItem(loginStatus)
  Cookies.remove(userinfo);
}

// 缓存用户openid、unionid信息
export function setOpenid(openid) {
  /* 如果cookie为session---静默清除---无操作20分钟心跳清除*/
  return Cookies.set('openid', openid); // 心跳
}

export function getOpenid() {
  return Cookies.get('openid');
}
export function removeOpenid() {
  return Cookies.remove('openid');
}
export function setUnionid(token) {
  /* 如果cookie为session---静默清除---无操作20分钟心跳清除*/
  return Cookies.set('unionid', token); // 心跳
}

export function getUnionid() {
  return Cookies.get('unionid');
}
export function removeUnionid() {
  return Cookies.remove('unionid');
}

// 保存用户targertid
export function setTargertid(value, day) {
  // sessionStorage.setItem(userinfo, JSON.stringify(value))
  Cookies.set(usertarget, value, {
    expires: day ? day : 1
  });
}

// 获取用户targertid
export function getTargertid() {
  // return JSON.parse(sessionStorage.getItem(usertarget))
  return Cookies.get(usertarget);
}
// 清空用户targertid
export function clearTtargertid() {
  // sessionStorage.removeItem(loginStatus)
  Cookies.remove(usertarget);
}

/**
 *
 * @param {获取手机验证码} value
 */
export function countDown(key, value) {
  var name = key ? key : findPwdDown;
  localStorage.setItem(name, value);
}
export function getCount(key) {
  var name = key ? key : findPwdDown;
  return localStorage.getItem(name);
}
export function clearCount(key) {
  var name = key ? key : findPwdDown;
  return localStorage.removeItem(name);
}

/**
 * @parmas 存储和获取当前tab导航状态
 */

export function setTabStatus(value) {
  sessionStorage.setItem(tabbarStatus, value);
}
export function getTabStatus() {
  return sessionStorage.getItem(tabbarStatus);
}
export function clearTabStatus() {
  sessionStorage.removeItem(tabbarStatus);
}

// 银行卡token
export function setbankStatus(value, day) {
  // sessionStorage.setItem(loginStatus,value)
  Cookies.set(loginStatusbank, value, {
    expires: day ? day : 1
  });
}

// 获取登录状态
export function getbankStatus() {
  // return sessionStorage.getItem(loginStatus)
  return Cookies.get(loginStatusbank);
}

// 清空登录状态
export function clearbankStatus() {
  // sessionStorage.removeItem(loginStatus)
  Cookies.remove(loginStatusbank);
}
// 银行用户id
export function setbankuserid(value, day) {
  // sessionStorage.setItem(loginStatus,value)
  Cookies.set(useridbank, value, {
    expires: day ? day : 1
  });
}

// 获取登录状态
export function getbankuserid() {
  // return sessionStorage.getItem(loginStatus)
  return Cookies.get(useridbank);
}

// 清空登录状态
export function clearbankuserid() {
  // sessionStorage.removeItem(loginStatus)
  Cookies.remove(useridbank);
}

// 获取IM 密钥 密钥ID(存储)
export function setSecret(value, day) {
  // sessionStorage.setItem(loginStatus,value)
  Cookies.set(gainSecret, value, {
    expires: day ? day : 1
  });
}

// 获取IM 密钥 密钥ID（获取）
export function getSecret() {
  // return sessionStorage.getItem(loginStatus)
  return Cookies.get(gainSecret);
}