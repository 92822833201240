import Vue from 'vue';
import Router from 'vue-router';
import { constantRouterMap } from './router.config.js';

// hack router push callback
var originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};
Vue.use(Router);
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // 如果你是 history模式 需要配置vue.config.js publicPath
    base: process.env.BASE_URL,
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRouterMap,
    // 设置路由页面
    mode: 'hash' // 路由地址url取消#符号
  });
};

var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
// })

export default router;