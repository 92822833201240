import Vue from 'vue';
//防止重复点击
var preventReClick = Vue.directive('preventReClick', {
  inserted: function inserted(el, binding) {
    el.addEventListener('click', function () {
      var events = el.style.pointerEvents;
      if (events == "") {
        el.style.pointerEvents = 'none';
        setTimeout(function () {
          el.style.pointerEvents = "";
        }, binding.value || 1000);
      }
    });
  }
});
export default preventReClick;