import request from '@/utils/request';
import { tapiApi, baseApi } from '@/config';
//当前合伙人信息
export function partnerDetail(params) {
  return request({
    url: tapiApi + "app/growth/partner/detail",
    method: 'get',
    params: params
  });
}
//合伙人入驻数量
export function partnerNumber(params) {
  return request({
    url: tapiApi + "app/growth/partner/number",
    method: 'get',
    params: params
  });
}
// 推广统计
export function getInviteInfo(params) {
  return request({
    url: tapiApi + "app/growth/partner/invite/statistics",
    method: 'get',
    params: params
  });
}
// 合伙人统计
export function execute_signed_count(data) {
  return request({
    url: baseApi + "gin/user/mission/task/execute_signed_count",
    method: 'get',
    data: data
  });
}
//我的邀请-列表
export function getMyInviteList(params) {
  return request({
    url: tapiApi + "app/growth/partner/invite",
    method: 'get',
    params: params
  });
}
//合伙人分享链接参数获取
export function getSharePartner(params) {
  return request({
    url: tapiApi + "app/growth/partner/share",
    method: 'get',
    params: params
  });
}

//发送手机验证码
export function getSendSms(params) {
  return request({
    url: tapiApi + "app/growth/partner/send/sms",
    method: 'get',
    params: params
  });
}
//拓成员创建
export function createMembers(data, token) {
  return request({
    url: tapiApi + "app/growth/partner",
    method: 'post',
    data: data,
    headers: {
      Authorization: token
    }
  });
}
//获取任务等级/任务列表接口
export function getQuest(data) {
  return request({
    url: baseApi + "/gin/user/growth/task-list",
    method: 'get',
    data: data
  });
}
//获取我的当前等级任务列表接口
export function getTaskLevelList(data) {
  return request({
    url: baseApi + "gin/user/growth/task-level-list",
    method: 'get',
    data: data
  });
}
// 拓客创建
export function partnerRegister(data, token) {
  return request({
    url: tapiApi + "/app/growth/partner/merchant",
    method: 'post',
    data: data,
    headers: {
      Authorization: "Bearer ".concat(token)
    }
  });
}
// 拓客创建
export function getShortToken(params, token) {
  return request({
    url: tapiApi + "app/growth/partner/short/token",
    method: 'get',
    params: params,
    headers: {
      Authorization: "Bearer ".concat(token)
    }
  });
}
// 拓客创建新
export function partnerRegisterCreate(data, token) {
  return request({
    url: tapiApi + "/app/mission/execute/merchants/create",
    method: 'post',
    data: data,
    headers: {
      Authorization: "Bearer ".concat(token)
    }
  });
}
// 邀请商家/物业参与活动
export function ActivityInvite(data, token) {
  return request({
    url: tapiApi + "/app/mission/execute/activity/invite",
    method: 'post',
    data: data,
    headers: {
      Authorization: "Bearer ".concat(token)
    }
  });
}
// 当前合伙人详情信息权益
export function PartnerDetailEquity(params) {
  return request({
    url: tapiApi + "app/growth/partner/detail",
    method: 'get',
    params: params
  });
}
// 当前合伙人详情信息权益
export function CheckFirst(params) {
  return request({
    url: baseApi + 'gin/user/growth/check-first',
    method: 'get',
    params: params
  });
}
//获取未签约列表接口
export function ExecuteSignedUnlist(params) {
  return request({
    url: baseApi + 'gin/user/mission/task/execute_signed_unlist',
    method: 'get',
    params: params
  });
}
//获取已签约列表接口
export function ExecuteSignedlist(params) {
  return request({
    url: baseApi + 'gin/user/mission/task/execute_signed_list',
    method: 'get',
    params: params
  });
}
//合伙人任务数量统计接口
export function MissionUserinfo(params) {
  return request({
    url: baseApi + 'gin/user/mission/userinfo',
    method: 'get',
    params: params
  });
}
//获取合伙人任务列表信息
export function TaskList(params) {
  return request({
    url: baseApi + 'gin/user/mission/task/list',
    method: 'get',
    params: params
  });
}
//任务认领明细列表接口
export function TaskAccept(params) {
  return request({
    url: baseApi + 'gin/user/mission/task/accept',
    method: 'get',
    params: params
  });
}
//获取任务执行明细列表
export function TaskExecute(params) {
  return request({
    url: baseApi + 'gin/user/mission/task/execute',
    method: 'get',
    params: params
  });
}
//悬赏任务中的钱包流水明细
export function MissionBill(params) {
  return request({
    url: baseApi + 'gin/user/mission/bill',
    method: 'get',
    params: params
  });
}
//认领任务详情信息
export function TaskInfo(params) {
  return request({
    url: baseApi + 'gin/user/mission/task/info',
    method: 'get',
    params: params
  });
}
//认领认领操作
export function TaskUsed(data) {
  return request({
    url: baseApi + 'gin/user/mission/task/used',
    method: 'post',
    data: data
  });
}
//任务悬赏结算明细
export function TaskSettle(params) {
  return request({
    url: baseApi + 'gin/user/mission/task/settle',
    method: 'get',
    params: params
  });
}
//企业查询
export function Queryingcompany(data, token) {
  return request({
    url: tapiApi + "app/growth/partner/company",
    method: 'get',
    data: data,
    headers: {
      Authorization: "Bearer ".concat(token)
    }
  });
}
//授权小区列表
export function Prepaylist(data, token) {
  return request({
    url: tapiApi + "/customer/v2/prepay/cs/sq/shop/list",
    method: 'get',
    data: data,
    headers: {
      Authorization: "Bearer ".concat(token)
    }
  });
}
//抢占广告位
export function Addposition(data) {
  return request({
    url: baseApi + 'gin/user/mission/ad/position/add',
    method: 'post',
    data: data
  });
}
//删除抢占广告位
export function Delposition(data) {
  return request({
    url: baseApi + 'gin/user/mission/ad/position/del',
    method: 'post',
    data: data
  });
}
//获取所属小区广告位
export function HavePosition(params) {
  return request({
    url: baseApi + 'gin/user/mission/ad/position',
    method: 'get',
    params: params
  });
}
// 查身份
export function Issub(data, token) {
  return request({
    url: tapiApi + "merchant/v2/sub/account/is/sub",
    method: 'get',
    data: data,
    headers: {
      Authorization: "Bearer ".concat(token)
    }
  });
}