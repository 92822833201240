import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
import _Notification2 from "element-ui/lib/theme-chalk/notification.css";
import "element-ui/lib/theme-chalk/base.css";
import _Notification from "element-ui/lib/notification";
import _MessageBox2 from "element-ui/lib/theme-chalk/message-box.css";
import "element-ui/lib/theme-chalk/base.css";
import _MessageBox from "element-ui/lib/message-box";
import _InputNumber2 from "element-ui/lib/theme-chalk/input-number.css";
import "element-ui/lib/theme-chalk/base.css";
import _InputNumber from "element-ui/lib/input-number";
import _Input2 from "element-ui/lib/theme-chalk/input.css";
import "element-ui/lib/theme-chalk/base.css";
import _Input from "element-ui/lib/input";
import _Tag2 from "element-ui/lib/theme-chalk/tag.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tag from "element-ui/lib/tag";
import _CascaderPanel2 from "element-ui/lib/theme-chalk/cascader-panel.css";
import "element-ui/lib/theme-chalk/base.css";
import _CascaderPanel from "element-ui/lib/cascader-panel";
import _Cascader2 from "element-ui/lib/theme-chalk/cascader.css";
import "element-ui/lib/theme-chalk/base.css";
import _Cascader from "element-ui/lib/cascader";
import _Card2 from "element-ui/lib/theme-chalk/card.css";
import "element-ui/lib/theme-chalk/base.css";
import _Card from "element-ui/lib/card";
import _MenuItemGroup2 from "element-ui/lib/theme-chalk/menu-item-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _MenuItemGroup from "element-ui/lib/menu-item-group";
import _MenuItem2 from "element-ui/lib/theme-chalk/menu-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _MenuItem from "element-ui/lib/menu-item";
import _TableColumn2 from "element-ui/lib/theme-chalk/table-column.css";
import "element-ui/lib/theme-chalk/base.css";
import _TableColumn from "element-ui/lib/table-column";
import _Table2 from "element-ui/lib/theme-chalk/table.css";
import "element-ui/lib/theme-chalk/base.css";
import _Table from "element-ui/lib/table";
import _OptionGroup2 from "element-ui/lib/theme-chalk/option-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _OptionGroup from "element-ui/lib/option-group";
import _Option2 from "element-ui/lib/theme-chalk/option.css";
import "element-ui/lib/theme-chalk/base.css";
import _Option from "element-ui/lib/option";
import _Loading2 from "element-ui/lib/theme-chalk/loading.css";
import "element-ui/lib/theme-chalk/base.css";
import _Loading from "element-ui/lib/loading";
import _Select2 from "element-ui/lib/theme-chalk/select.css";
import "element-ui/lib/theme-chalk/base.css";
import _Select from "element-ui/lib/select";
import _ButtonGroup2 from "element-ui/lib/theme-chalk/button-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _ButtonGroup from "element-ui/lib/button-group";
import _Button2 from "element-ui/lib/theme-chalk/button.css";
import "element-ui/lib/theme-chalk/base.css";
import _Button from "element-ui/lib/button";
import _Row2 from "element-ui/lib/theme-chalk/row.css";
import "element-ui/lib/theme-chalk/base.css";
import _Row from "element-ui/lib/row";
import _Step2 from "element-ui/lib/theme-chalk/step.css";
import "element-ui/lib/theme-chalk/base.css";
import _Step from "element-ui/lib/step";
import _Steps2 from "element-ui/lib/theme-chalk/steps.css";
import "element-ui/lib/theme-chalk/base.css";
import _Steps from "element-ui/lib/steps";
import _Image2 from "element-ui/lib/theme-chalk/image.css";
import "element-ui/lib/theme-chalk/base.css";
import _Image from "element-ui/lib/image";
import _Progress2 from "element-ui/lib/theme-chalk/progress.css";
import "element-ui/lib/theme-chalk/base.css";
import _Progress from "element-ui/lib/progress";
import _Upload2 from "element-ui/lib/theme-chalk/upload.css";
import "element-ui/lib/theme-chalk/base.css";
import _Upload from "element-ui/lib/upload";
import _Icon2 from "element-ui/lib/theme-chalk/icon.css";
import "element-ui/lib/theme-chalk/base.css";
import _Icon from "element-ui/lib/icon";
import _Slider2 from "element-ui/lib/theme-chalk/slider.css";
import "element-ui/lib/theme-chalk/base.css";
import _Slider from "element-ui/lib/slider";
import _Alert2 from "element-ui/lib/theme-chalk/alert.css";
import "element-ui/lib/theme-chalk/base.css";
import _Alert from "element-ui/lib/alert";
import _Tree2 from "element-ui/lib/theme-chalk/tree.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tree from "element-ui/lib/tree";
import _TabPane2 from "element-ui/lib/theme-chalk/tab-pane.css";
import "element-ui/lib/theme-chalk/base.css";
import _TabPane from "element-ui/lib/tab-pane";
import _Tabs2 from "element-ui/lib/theme-chalk/tabs.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tabs from "element-ui/lib/tabs";
import _FormItem2 from "element-ui/lib/theme-chalk/form-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _FormItem from "element-ui/lib/form-item";
import _Form2 from "element-ui/lib/theme-chalk/form.css";
import "element-ui/lib/theme-chalk/base.css";
import _Form from "element-ui/lib/form";
import Vue from 'vue';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(_Form);
Vue.use(_FormItem);
Vue.use(_Tabs);
Vue.use(_TabPane);
Vue.use(_Tree);
Vue.use(_Alert);
Vue.use(_Slider);
Vue.use(_Icon);
Vue.use(_Upload);
Vue.use(_Progress);
Vue.use(_Image);
Vue.use(_Steps);
Vue.use(_Step);
Vue.use(_Row);
Vue.use(_Button);
Vue.use(_ButtonGroup);
Vue.use(_Select);
Vue.use(_Loading.directive);
Vue.use(_Option);
Vue.use(_OptionGroup);
Vue.use(_Table);
Vue.use(_TableColumn);
Vue.use(_MenuItem);
Vue.use(_MenuItemGroup);
Vue.use(_Card);
Vue.use(_Cascader);
Vue.use(_CascaderPanel);
Vue.use(_Tag);
Vue.use(_Input);
Vue.use(_InputNumber);
Vue.prototype.$loading = _Loading.service;
Vue.prototype.$msgbox = _MessageBox;
Vue.prototype.$alert = _MessageBox.alert;
Vue.prototype.$confirm = _MessageBox.confirm;
Vue.prototype.$prompt = _MessageBox.prompt;
Vue.prototype.$notify = _Notification;
Vue.prototype.$message = _Message;