import "core-js/modules/es.number.constructor.js";
import { setTabStatus, getTabStatus } from '@/utils/cookies';
import utils from 'liudonghan';
export default {
  name: 'TabBar',
  props: {
    defaultActive: {
      type: Number,
      default: 0
    },
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      active: 0
    };
  },
  methods: {
    handleChange: function handleChange(value) {
      setTabStatus(value);
      this.$emit('change', value);
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.active = getTabStatus() || this.defaultActive;
    utils.EventBusManagerUtils.register('changeTabStatus', function (value) {
      _this.active = value;
      setTabStatus(value);
    });
    // this.$bus.$on('changeTabStatus', (value) => {
    //     this.active = value;
    //     setTabStatus(value)
    // })
  }
};