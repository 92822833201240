var getters = {
  userName: function userName(state) {
    return state.app.userName;
  },
  userInfoByToken: function userInfoByToken(state) {
    return state.user.userInfoByToken;
  },
  deviceEnvironment: function deviceEnvironment(state) {
    return state.user.deviceEnvironment;
  },
  userIdentity: function userIdentity(state) {
    return state.user.userIdentity;
  },
  partnerInfo: function partnerInfo(state) {
    return state.partner.partnerInfo;
  }
};
export default getters;