import "core-js/modules/es.object.to-string.js";
import axios from 'axios';
import { getLoginStatus } from './cookies';
var service = axios.create({
  timeout: 5000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  var token = getLoginStatus();
  if (token) {
    config.headers['Authorization'] = "Bearer ".concat(token);
  }
  if (config.data && config.data.token) {
    config.headers['Authorization'] = "Bearer ".concat(config.data.token);
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  var data = response.data;
  var request = response.request;
  if (request.status === 200) {
    return Promise.resolve(data);
  } else {
    Toast.fail({
      message: '服务器连接异常，请稍后重试！',
      duration: 1000
    });
  }
}, function (error) {
  // console.log('err' + error)
  return Promise.reject(error);
});
export default service;