import "vant/es/index-anchor/style";
import _IndexAnchor from "vant/es/index-anchor";
import "vant/es/index-bar/style";
import _IndexBar from "vant/es/index-bar";
import "vant/es/calendar/style";
import _Calendar from "vant/es/calendar";
import "vant/es/image-preview/style";
import _ImagePreview from "vant/es/image-preview";
import "vant/es/goods-action-icon/style";
import _GoodsActionIcon from "vant/es/goods-action-icon";
import "vant/es/goods-action-button/style";
import _GoodsActionButton from "vant/es/goods-action-button";
import "vant/es/goods-action/style";
import _GoodsAction from "vant/es/goods-action";
import "vant/es/coupon-cell/style";
import _CouponCell from "vant/es/coupon-cell";
import "vant/es/coupon-list/style";
import _CouponList from "vant/es/coupon-list";
import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/dropdown-menu/style";
import _DropdownMenu from "vant/es/dropdown-menu";
import "vant/es/dropdown-item/style";
import _DropdownItem from "vant/es/dropdown-item";
import "vant/es/swipe-cell/style";
import _SwipeCell from "vant/es/swipe-cell";
import "vant/es/cascader/style";
import _Cascader from "vant/es/cascader";
import "vant/es/lazyload/style";
import _Lazyload from "vant/es/lazyload";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/number-keyboard/style";
import _NumberKeyboard from "vant/es/number-keyboard";
import "vant/es/password-input/style";
import _PasswordInput from "vant/es/password-input";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/popover/style";
import _Popover from "vant/es/popover";
import "vant/es/datetime-picker/style";
import _DatetimePicker from "vant/es/datetime-picker";
import "vant/es/form/style";
import _Form from "vant/es/form";
import "vant/es/area/style";
import _Area from "vant/es/area";
import "vant/es/badge/style";
import _Badge from "vant/es/badge";
import "vant/es/overlay/style";
import _Overlay from "vant/es/overlay";
import "vant/es/rate/style";
import _Rate from "vant/es/rate";
import "vant/es/slider/style";
import _Slider from "vant/es/slider";
import "vant/es/count-down/style";
import _CountDown from "vant/es/count-down";
import "vant/es/share-sheet/style";
import _ShareSheet from "vant/es/share-sheet";
import "vant/es/contact-card/style";
import _ContactCard from "vant/es/contact-card";
import "vant/es/stepper/style";
import _Stepper from "vant/es/stepper";
import "vant/es/sku/style";
import _Sku from "vant/es/sku";
import "vant/es/tree-select/style";
import _TreeSelect from "vant/es/tree-select";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/card/style";
import _Card from "vant/es/card";
import "vant/es/collapse-item/style";
import _CollapseItem from "vant/es/collapse-item";
import "vant/es/collapse/style";
import _Collapse from "vant/es/collapse";
import "vant/es/steps/style";
import _Steps from "vant/es/steps";
import "vant/es/step/style";
import _Step from "vant/es/step";
import "vant/es/divider/style";
import _Divider from "vant/es/divider";
import "vant/es/switch/style";
import _Switch from "vant/es/switch";
import "vant/es/radio-group/style";
import _RadioGroup from "vant/es/radio-group";
import "vant/es/radio/style";
import _Radio from "vant/es/radio";
import "vant/es/action-sheet/style";
import _ActionSheet from "vant/es/action-sheet";
import "vant/es/submit-bar/style";
import _SubmitBar from "vant/es/submit-bar";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/checkbox-group/style";
import _CheckboxGroup from "vant/es/checkbox-group";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/picker/style";
import _Picker from "vant/es/picker";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/search/style";
import _Search from "vant/es/search";
import "vant/es/empty/style";
import _Empty from "vant/es/empty";
import "vant/es/row/style";
import _Row from "vant/es/row";
import "vant/es/col/style";
import _Col from "vant/es/col";
import "vant/es/grid-item/style";
import _GridItem from "vant/es/grid-item";
import "vant/es/grid/style";
import _Grid from "vant/es/grid";
import "vant/es/notice-bar/style";
import _NoticeBar from "vant/es/notice-bar";
import "vant/es/sticky/style";
import _Sticky from "vant/es/sticky";
import "vant/es/uploader/style";
import _Uploader from "vant/es/uploader";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/nav-bar/style";
import _NavBar from "vant/es/nav-bar";
import "vant/es/swipe-item/style";
import _SwipeItem from "vant/es/swipe-item";
import "vant/es/swipe/style";
import _Swipe from "vant/es/swipe";
import "vant/es/pull-refresh/style";
import _PullRefresh from "vant/es/pull-refresh";
import "vant/es/skeleton/style";
import _Skeleton from "vant/es/skeleton";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/button/style";
import _Button from "vant/es/button";
// 按需全局引入 vant组件
import Vue from 'vue';
Vue.use(_Button);
Vue.use(_Cell);
Vue.use(_List);
Vue.use(_Tabbar).use(_TabbarItem);
Vue.use(_Image);
Vue.use(_Skeleton);
Vue.use(_PullRefresh);
Vue.use(_Swipe);
Vue.use(_SwipeItem);
Vue.use(_NavBar);
Vue.use(_Icon);
Vue.use(_Field);
Vue.use(_Tab);
Vue.use(_Tabs);
Vue.use(_Uploader);
Vue.use(_Sticky);
Vue.use(_NoticeBar);
Vue.use(_Grid);
Vue.use(_GridItem);
Vue.use(_Col);
Vue.use(_Row);
Vue.use(_Empty);
Vue.use(_Search);
Vue.use(_Popup);
Vue.use(_Picker);
Vue.use(_Checkbox);
Vue.use(_CheckboxGroup);
Vue.use(_Dialog);
Vue.use(_SubmitBar);
Vue.use(_ActionSheet);
Vue.use(_Radio);
Vue.use(_RadioGroup);
Vue.use(_Switch);
Vue.use(_Divider);
Vue.use(_Step);
Vue.use(_Steps);
Vue.use(_Collapse);
Vue.use(_CollapseItem);
Vue.use(_Card);
Vue.use(_Tag);
Vue.use(_TreeSelect);
Vue.use(_Sku);
Vue.use(_Stepper);
Vue.use(_ContactCard);
Vue.use(_ShareSheet);
Vue.use(_CountDown);
Vue.use(_Slider);
Vue.use(_Rate);
Vue.use(_Overlay);
Vue.use(_Badge);
Vue.use(_Area);
Vue.use(_Form);
Vue.use(_DatetimePicker);
Vue.use(_Popover);
Vue.use(_Toast);
Vue.use(_PasswordInput);
Vue.use(_NumberKeyboard);
Vue.use(_CellGroup);
Vue.use(_Lazyload, {
  lazyComponent: true
});
Vue.use(_Cascader);
Vue.use(_SwipeCell);
Vue.use(_DropdownItem);
Vue.use(_DropdownMenu);
Vue.use(_Loading);
Vue.use(_CouponList);
Vue.use(_CouponCell);
Vue.use(_GoodsAction);
Vue.use(_GoodsActionButton);
Vue.use(_GoodsActionIcon);
Vue.use(_ImagePreview);
Vue.use(_Calendar);
Vue.use(_IndexBar);
Vue.use(_IndexAnchor);