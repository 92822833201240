var state = {
  userName: ''
};
var mutations = {
  SET_USER_NAME: function SET_USER_NAME(state, name) {
    state.userName = name;
  }
};
var actions = {
  // 设置name
  setUserName: function setUserName(_ref, name) {
    var commit = _ref.commit;
    commit('SET_USER_NAME', name);
  }
};
export default {
  state: state,
  mutations: mutations,
  actions: actions
};