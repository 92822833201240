import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import Cookies from 'js-cookie';
var loginStatus = 'login-status';
var userinfo = 'hfhx-userinfo';
var findPwdDown = 'zhm-mdjs';

// 设置登陆状态和失效，默认7天
export function setLoginStatus(value) {
  Cookies.set(loginStatus, value, {
    expires: 5
  });
}

// 获取登录状态
export function getLoginStatus() {
  return Cookies.get(loginStatus);
}

// 清空登录状态
export function clearLoginStatus() {
  Cookies.remove(loginStatus);
}
export function setUserInfo(value) {
  Cookies.set(userinfo, JSON.stringify(value), {
    expires: 5
  });
}
export function getUserInfo(value) {
  return JSON.parse(Cookies.get(userinfo) || '{}');
}
export function countDown(value) {
  sessionStorage.setItem(findPwdDown, value);
}
export function getCount() {
  return sessionStorage.getItem(findPwdDown);
}
export function clearCount() {
  return sessionStorage.removeItem(findPwdDown);
}