import request from '@/utils/request';
import { IMApi, orderApi, baseApi, liveApi } from '@/config';
import { getTargertid } from '@/utils/cookies';

//登录腾讯IM 获取用户签名 
export function getUserSign(data) {
  return request({
    url: IMApi + 't_chat/getUserSign',
    method: 'post',
    data: data
  });
}

// IM鉴权接口
export function IMauth(data) {
  return request({
    url: IMApi + 'h5/auth_message/auth',
    method: 'post',
    data: data
  });
}
// IM用户注册
export function userRegister(data) {
  return request({
    url: IMApi + 'j_user/userRegister',
    method: 'post',
    data: data
  });
}

// IM消息列表
export function sendNews(data) {
  return request({
    url: IMApi + 'j_group/sendNewsRecord',
    method: 'post',
    data: data,
    headers: {
      targetId: getTargertid()
    }
  });
}

// IM历史消息
export function historyNews(data) {
  return request({
    url: IMApi + 'h5/single_chat/historyNews',
    method: 'post',
    data: data,
    headers: {
      targetId: getTargertid()
    }
  });
}

// 根据店铺查询店铺下所有商品
export function IMSendOrderInfo(params) {
  return request({
    url: orderApi + 'api/CallBack/IM_SendOrderInfo',
    method: 'get',
    params: params
  });
}

// 黑名单列表
export function blackList(data) {
  return request({
    url: IMApi + 'j_black/blackList',
    method: 'post',
    data: data,
    headers: {
      targetId: getTargertid()
    }
  });
}

// 获取群组详情
export function queryGroupInfo(data) {
  return request({
    url: IMApi + 'j_group/queryGroupInfo',
    method: 'post',
    data: data,
    headers: {
      targetId: getTargertid()
    }
  });
}

// 添加群组成员
export function addMembers(data) {
  return request({
    url: IMApi + 'j_group/addMembers',
    method: 'post',
    data: data,
    headers: {
      targetId: getTargertid()
    }
  });
}

// 用户搜索加入群组
export function addGroupUser(data) {
  return request({
    url: IMApi + 'j_group/addGroupUser',
    method: 'post',
    data: data,
    headers: {
      targetId: getTargertid()
    }
  });
}

// 获取平台用户信息
export function platFormUserInfo(data) {
  return request({
    url: 'https://im.xinfushenghuo.cn/j_user/platFormUserInfo',
    method: 'post',
    data: data,
    headers: {
      targetId: getTargertid()
    }
  });
}
// 获取IM 密钥 密钥ID
export function gainSecret(data) {
  return request({
    url: IMApi + "j_user/gainSecret",
    method: 'post',
    data: data
  });
}
// 活动直播-添加活动
export function addSetService(data) {
  return request({
    url: IMApi + "h5/set_live_activity_config/setService",
    method: 'post',
    data: data,
    headers: {
      targetId: getTargertid()
    }
  });
}

// 店铺列表
export function shopInfo(params) {
  return request({
    url: baseApi + "apishop/get/shop-info",
    method: 'get',
    params: params
  });
}

// 我的关注列表
export function reviewedFriendsList(data) {
  return request({
    url: IMApi + "j_friends_user/reviewedFriendsList",
    method: 'post',
    data: data,
    headers: {
      targetId: getTargertid()
    }
  });
}
// 邀请主播接口
export function addInviteAnchor(params) {
  return request({
    url: IMApi + "h5/set_live_activity_config/inviteAnchor",
    method: 'get',
    params: params,
    headers: {
      targetId: getTargertid()
    }
  });
}
// 取消主播邀请
export function deleteOffInvite(params) {
  return request({
    url: IMApi + "h5/set_live_activity_config/offInvite",
    method: 'get',
    params: params,
    headers: {
      targetId: getTargertid()
    }
  });
}
// 选择话题获取根分类和搜索
export function getTopic(data) {
  return request({
    url: liveApi + "home/getTopic",
    method: 'post',
    data: data
  });
}